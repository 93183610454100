import { readonly } from 'vue'
import { Cog6ToothIcon, HomeIcon } from '@heroicons/vue/24/outline'

export function usePlatformNavigation() {
  const userTenant = useTenantStore()
  const { userActiveTenant, userActiveTenantLoading } = storeToRefs(userTenant)
  const navigation = ref([
    {
      name: 'Account Overview',
      link: '/dashboard/account-overview',
      icon: HomeIcon,
      current: true,
    },
  ])
  const route = useRoute()

  watchEffect(() => {
    if (
      userActiveTenant.value
      && !userActiveTenantLoading.value
      && userActiveTenant.value.onboarding.step === 'integration_instructions'
    ) {
      const navItemAlreadyPresent = !!navigation.value.find(navItem => navItem.name === 'Integration Instructions')

      if (!navItemAlreadyPresent) {
        navigation.value = navigation.value.concat([
          {
            name: 'Integration Instructions',
            link: '/dashboard/integration-instructions',
            icon: Cog6ToothIcon,
            current: false,
          },
        ])
      }
    }
  })

  watchEffect(() => {
    navigation.value.forEach((navItem) => {
      navItem.current = route.path === navItem.link
    })
  })

  return {
    navigation: readonly(navigation),
  }
}
